
import PriceHelpers from '../mixins/prices'

export default {
  name: 'FreePage',
  mixins: [PriceHelpers],
  components: {
    FooterGlowWrapper: () => import('../components/elements/footer-glow-wrapper.vue')
  },
  data: () => ({
    selectedMonth: 12, // выводим цены с учетом выбора месяца
    tab: 'cloud'
  }),
  methods: {
    tariffStart () {
      return this.getTariffList(this.tab).find((tariff) => tariff.code === 'startlite')
    },
    formParams (tariff) {
      let subtitleString = this.$t('regForm.subtitle')
      if (tariff === 'startlite') {
        subtitleString = ''
      }
      return {
        subtitle: subtitleString,
        header: this.$i18n.nl2br('regForm.title')
      }
    }
  },
  head () {
    return {
      title: this.$t('pages.free.meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('pages.free.meta.description')
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  }
}
